import React from "react"
import ImageMeta from "../components/ImageMeta"
import Layout from "../components/layout"
import Seamless from "../components/Seamless"
import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import "../templates/treatmentOptions.scss"

import TheaterVideo from "../components/Theater/TheaterVideo"
import Button from '@bit/azheng.joshua-tree.button';
import ButtonGroup from '@bit/azheng.joshua-tree.button-group';
import CTA from "../components/CTA/CTA"
import SEO from "../components/seo"
import { Link } from 'gatsby'

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"


const ThankYouPage = (props) => {
  return (
    <SharedStateProvider>
      <Layout>
        <SEO title="Online Video Consultations at Oral Surgery & Dental Implant Specialists South Carolina" description="Thank you for submitting your form for an online consultation at Oral Surgery & Dental Implant Specialists South Carolina in Mount Pleasant, SC." />
        <div className="joshua-tree-content thank-you">
          <div className="columns top-section white-back">
            <div className="column is-4"></div>
            <div className="column">
              <h1>
                Thank You
              </h1>
              <h5>Success! Your form was sent.</h5>
            </div>
          </div>

          <div className="columns">
            <div className="column is-4"></div>
            <div className="column">
            <p className="large">You have successfully completed an online consultation form. A member of our team will be in contact with you soon. We invite you to explore our website, where you can learn more about Dr. Barefoot, watch video reviews from our patients, and learn about the variety of procedures that we offer.</p>
<p className="large">If you have any questions or need immediate attention, please contact our office at <a href="tel:+18438495188">(843) 849-5188</a>.</p>
            </div>
            <div className="column is-1"></div>
            <div className="column is-3 side-button-block">
              <p className="small"><strong>If you need immediate attention, please contact our office.</strong></p>
              <a href="tel:+18438495188" className="standard-button covid-button-text standalone">(843) 849-5188</a>
            </div>
            <div className="column is-4"></div>
          </div>


        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default ThankYouPage